.datepicker {
    width: 100%;
    padding: 18px;
    border: 1px solid #bdbaba;
    border-radius: 10px;
    background: #fafafa;
    color: #939393;
}
.datepicker2 {
    width: 100%;
    padding: 18px;
    border: 1px solid #bdbaba;
    border-radius: 10px;
    background: #fafafa;
    color: #1c1c1c;
    font-weight: 500;
}

.datepicker.hover{
    border: 1px solid blueviolet;
}

.datepic{
    width: 97%;
    padding: 18px;
    border: 1px solid #bdbaba;
    border-radius: 10px;
    background: #fafafa;
}

.width{
    width: 97%;
}



.button{
    color: #fff;
}
